$(() => {
  $('[data-reset-form]').click(() => {
    let form = $('#entity-form-search')
    if (form.length) {
      form.trigger('reset')

      let inputs = $(':input', form).not(':button, :submit, :reset, :hidden, :checkbox, :radio')

      for (let input of inputs) {
        let selectInput = $(input)
        if (selectInput.hasClass('js-select2')) {
          selectInput.val(null).trigger('change')
        } else {
          selectInput.removeAttr('value')
        }
      }

      $(':checkbox, :radio', form).removeAttr('checked')
    }
  })
})
