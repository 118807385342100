const queryString = require('query-string')

$(() => {

  let blockLoading = '#app form'

  let loading = function () {
    mApp.block(blockLoading, {})
  }

  let unLoading = function () {
    mApp.unblock(blockLoading)
  }

  const hasDangerClass = 'has-danger'

  $('.js-report-export').click((ev) => {

    ev.preventDefault()
    ev.stopPropagation()

    let reportId = $('.js-report-report').val()
    let organization = $('.js-report-organization')
    let organizationId = organization.val()
    let errorOrganization = organization.data('error')
    let errorClass = '.form-control-feedback'
    const reportConfiguration = queryString.parse($('#tab_generateTab :input').serialize())
    let father = organization.parent()

    if (organizationId.length <= 0) {

      father.addClass(hasDangerClass)

      if (!father.find(errorClass).length) {
        father.append('<span class="form-control-feedback">' + errorOrganization + '</span>')
      }

      return
    }

    loading()


    const type = window.location.href.match(/my-reports/) ? 'my-report' : 'report'

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    const routeData = route(type + '.export', {
      report: reportId,
      organizations: organizationId,
    }, false)
    axios.post(routeData, $('#tab_generateTab :input').serialize(), { responseType: 'blob', headers }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      const filename = response.headers['content-disposition'].match(/filename=("?)((?:\\"|.)+)\1/)[2]
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      setTimeout(() => document.body.removeChild(link), 500)
    }).catch(() => {
      Swal.fire({
        type: 'error',
        title: 'El informe no contiene datos'
      })
    }).finally(unLoading)
  })

  $('.js-report-preview').click((ev) => {

    let reportId = $('.js-report-report').val()
    let organization = $('.js-report-organization')
    let father = organization.parent()
    let organizationId = organization.val()
    let errorOrganization = organization.data('error')
    let errorClass = '.form-control-feedback'
    let iframe = $('.js-report-iframe')

    if (organizationId.length <= 0) {

      father.addClass(hasDangerClass)

      if (!father.find(errorClass).length) {
        father.append('<span class="form-control-feedback">' + errorOrganization + '</span>')
      }

      return
    }

    father.removeClass(hasDangerClass)

    if (father.find(errorClass).length) {
      father.find(errorClass).remove()
    }

    const reportConfiguration = queryString.parse($('#tab_generateTab :input').serialize())

    ev.preventDefault()
    ev.stopPropagation()

    loading()

    iframe.on('load', function () {
      unLoading()
      iframe.contents().find('td').css('padding', '2px')
    })

    const type = window.location.href.match(/my-reports/) ? 'my-report' : 'report'

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    const routeData = route(type + '.preview', {
      report: reportId,
      organizations: organizationId,
    }, false)
    axios.post(routeData,
      $('#tab_generateTab :input').serialize(),
      {headers}
    ).then(response => {
      console.log(response)
      $(iframe).contents().find('body').html(response.data)
    }).catch((e) => {
      $(iframe).contents().find('body').html('')
      Swal.fire({
        type: 'error',
        title: 'El informe no contiene datos'
      })
    }).finally(unLoading)
  })

  $('#tab_generateTab').ready(() => {
    $('#tab_generateTab *[disabled]').attr('disabled', false)
  })

})
