const JS_NAV_ITEM = '.js-nav-item'
$(() => {
  //  Basurillas del edge
  const $submitButton = $('.js-submit-form').first()
  const $form = $submitButton.closest('form')

  $submitButton.unbind('click')
  $submitButton.click(function (ev) {
    ev.preventDefault()
    if (!$form.checkValidity || $form.checkValidity()) {
      $('[type="submit"]').off()
      $('form').off()
      $(this).unbind('click')
      $form.submit()
    }
  })

  $('.js-saving-inputs').on('change dp.change', 'input, select, textarea', (input) => {
    $(input.currentTarget).addClass('dirty')
  })

  function getDirtyFieldsCount () {
    return $('.js-saving-inputs').find('.dirty').length > 0
  }

  function preventTabChange (e) {
    Swal.fire({
      title: trans.trans('forms.change_tab_title'),
      text: trans.trans('forms.change_tab_text'),
      confirmButtonText: trans.trans('forms.change_tab_confirm'),
      cancelButtonText: trans.trans('forms.change_tab_cancel'),
      type: 'warning',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        $submitButton.click()
      }
    })

    e.stopPropagation()
    e.preventDefault()
    return false
  }

  let requiringSaveTabActive = false

  const jsNameTab = '.js-form-tab'


  function setCurrentTabHashInFormAction (currentTab) {
    const form = currentTab.closest('form')
    const currentUrl = new URL(form.action)
    currentUrl.searchParams.delete('currentTab')
    currentUrl.searchParams.append('currentTab', currentTab.id)
    form.action = currentUrl.toString()
  }

  function setActiveWhenTabIdsWithDot (currentTabId) {
    if (!currentTabId.includes('.')) {
      return
    }

    $(".tab-pane").removeClass('active');
    document.getElementById(`tab_${currentTabId}`).classList.add('active');
  }

  // Si viene un # en la url que coincida con el id de alguna pestaña se activa
  $(jsNameTab).each((index, tab) => {
    if (`#${tab.id}` === window.location.hash) {
      bootstrap.Tab.getOrCreateInstance(tab).show()
      setCurrentTabHashInFormAction(tab)
      // Necesario para log de actividad, porque introduce . en los ids de las tabs
      setActiveWhenTabIdsWithDot(tab.id)
    }
  })

  $('#app').on('click', jsNameTab, (e) => {
    const tab = e.currentTarget
   // bootstrap.Tab.getInstance(tab).show()
    const $tab = $(tab)

    const hash = tab.id;
    const newUrl = window.location.href.split("#")[0] + `#${hash}`;
    history.replaceState(null, null, newUrl);

    setCurrentTabHashInFormAction(tab)

    const requireSaveClass = 'js-require-save'
    if (getDirtyFieldsCount() && $tab.hasClass(requireSaveClass)) {
      return preventTabChange(e)
    }

    // Obligar a guardar al salir de pestaña con require-save, salvo que esté permitido
    if (getDirtyFieldsCount() && requiringSaveTabActive && !$tab.hasClass(requireSaveClass)) {
      return preventTabChange(e)
    }

    const allowReturnWithoutSavingClass = 'js-allow_return_without_saving'
    requiringSaveTabActive = $tab.hasClass(requireSaveClass) && !$tab.hasClass(allowReturnWithoutSavingClass)

    // Necesario para log de actividad, porque introduce . en los ids de las tabs
    setActiveWhenTabIdsWithDot(hash)

    return true
  })

  let jsActionButtonClicked = false
  $('.js-form-buttons').find('a, button').on('click', (e) => {
    jsActionButtonClicked = true
  })

  $(window).bind('beforeunload', () => {
    if (getDirtyFieldsCount() && !jsActionButtonClicked) {
      return trans.trans('forms.unload_title')
    }
  })

  const errorFields = $('.has-danger')
  errorFields.each((i, el) => {
    const $tabLink = $(`[href="#${$(el).closest('.tab-pane').prop('id')}"]`)
    $(el).closest('.m-portlet__body').addClass('js-submitted-with-errors')
    $tabLink.addClass('has-errors')
    $tabLink.find('i').addClass('has-errors')

    if ($(el)[0] === errorFields.last()[0]) {
      const firstErrorTab = $(jsNameTab + '.has-errors').first()
      if (firstErrorTab) {
        firstErrorTab.trigger('click')
        errorFields.first().find('input').focus()
      }
    }
  })

  let tabs = []
  let tabsWidth = null
  let toolBoxWidth = null

  function hideOverflowedTabs (availableWidth, $dropdown, $hiddenContainer) {
    const excess = tabsWidth - availableWidth
    tabs.reverse().reduce((accum, tab) => {
      if (accum <= excess) {
        let clone = $(tab.element).find(jsNameTab).clone(true)
        clone.removeClass('nav-link m-tabs__link').addClass('dropdown-item')
        $dropdown.append(clone)
        $(tab.element).addClass('hide')
      }
      return accum + tab.width
    }, 0)
    tabs.reverse()
    $hiddenContainer.removeClass('hide')
  }

  const hideFormTabs = () => {
    let $formHead = $('.js-form-head')
    let $formTabs = $formHead.find('.js-form-buttons')
    let $hiddenContainer = $formHead.find('.js-hidden-form-tabs')
    let $dropdown = $hiddenContainer.find('.dropdown-menu')
    $dropdown.empty()
    toolBoxWidth = toolBoxWidth ? toolBoxWidth : $formTabs.length ? $formTabs.get(0).getBoundingClientRect().width : 0
    tabsWidth = tabsWidth ? tabsWidth : $formHead.find('.js-form-tools .js-nav-item')
      .map((_, elem) => $(elem).outerWidth(true))
      .toArray()
      .reduce((a, b) => a + b, 0)
    // 30 para el tamaño del dropdown y X para el padding
    const availableWidth = $formHead.get(0).getBoundingClientRect().width - toolBoxWidth - 30 - 70

    $formHead.find(JS_NAV_ITEM).each((index, element) => $(element).removeClass('hide'))

    if (!tabs.length) { loadTabs($formHead) }

    $hiddenContainer.addClass('hide')
    if (tabsWidth > availableWidth) { hideOverflowedTabs(availableWidth, $dropdown, $hiddenContainer) }
  }

  function loadTabs ($formHead) {
    $formHead.find(JS_NAV_ITEM).each(function (index, element) {
      let width = $(element).outerWidth(true)
      tabs.push({ index, element, width })
    })
    window.h = hideFormTabs
  }

  $(document).ready(() => {
    $('.js-avoid-click').remove()

    if ($(JS_NAV_ITEM).length) {
      hideFormTabs()
      window.addEventListener('resize', _.debounce(hideFormTabs, 700), {  passive: true })
    }

    // Form tabs click
    const urlParams = new URLSearchParams(window.location.search)
    const formTab = urlParams.get('formTab')
    if (formTab) {
      const tabHref = '#' + formTab
      $(`[href="${tabHref}"]`).click()
    }
  })

  $(document).ready(function () {
    $('.inherit-field').each(function () {
      $(this).prop('disabled', true)
    })
  })
})
