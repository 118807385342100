const pace = require('pace-js')
pace.options.ajax.trackWebSockets = false
pace.start()

import Lang from 'lang.js'
import messages from './shared/messages'
import Swal from 'sweetalert2'
import moment from 'moment'
import * as constants from './shared/constants.json'

/** Globals **/
window.Swal = Swal
window.Cookies = require('js-cookie')
window.moment = moment
window.node_env = process.env.NODE_ENV
window.MODEL_CONSTANTS = constants

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
  window.$ = window.jQuery = require('jquery')

  window.trans = new Lang({ messages })

  //window.bootstrap = require('bootstrap') // TODO: si se empieza usar el de plugins de metronic seguramente toque quitarlo
  //window.toastr = require('toastr') // TODO: si se empieza usar el de plugins de metronic seguramente toque quitarlo
  require('./shared/global')
} catch (e) {
}

/**
 * TODO: Seguramente eliminarlo al usar metronic 8
 * Layout
 */
//require('./layout/config')
//require('./main-layout')

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios')

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}
