import axios from "axios";
import Swal from "sweetalert2";
("use strict");

var KTSigninGeneral = (function() {
  // Elements

  var submitButton;

  var sendCode;

  var handleForm = function(e) {
    // Handle form submit

    submitButton.addEventListener("click", async function(e) {
      // Prevent button default action

      e.preventDefault();

      const userEmail = document.getElementById("login-email").value;
      const userPassword = document.getElementById("login-password").value;

      const response = await axios.post(`/login/two-factor-auth`, {
        email: userEmail,
        password: userPassword
      });

      if (response.data.response === true) {
        var myModal = new bootstrap.Modal(
          document.getElementById("two-factor-auth-modal")
        );

        myModal.show();

        sendCode = document.querySelector("#two-factor-auth-send-code");

        sendCode.addEventListener("click", async function() {
          const code = document.getElementById("two-factor-auth-code").value;
          const twoFactor = await axios.post(`/login/two-factor-auth/code`, {
            code: code
          });

          if (twoFactor.data.response === true) {
            document.getElementById("login-form").submit();
          } else {
            Swal.fire({
              text: "El código introducido es incorrecto",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary"
              }
            });
          }
        });
      } else {
        document.getElementById("login-form").submit();
      }
    });
  };

  return {
    // Initialization

    init: function() {
      submitButton = document.querySelector("#kt_sign_in_submit");

      if (submitButton) {
        handleForm();
      }
    }
  };
})();

// On document ready

KTUtil.onDOMContentLoaded(function() {
  KTSigninGeneral.init();
});
