$(() => {

  $('.js-sendgrid-template').on('select2:select', async function (e) {

    let data = e.params.data
    let detail = ''
    const response = await axios.get(`/api/crm/templates/${data.id}`)
    const template = response.data

    if (template.versions.length) {
      detail = template.versions[0].html_content
    }

    let event = new CustomEvent('updateTextArea', { 'detail': detail })
    document.dispatchEvent(event)
  })

})
