$(() => {

  $('.js-submit-form').click(function (ev) {
    ev.preventDefault()
    const form = $(this).closest('form')
    form.on('submit', function () {
      this.submit()
    })
    $(this).unbind('click')
    form.submit()
  })

  $('.js-translation-export').click((ev) => {
    ev.preventDefault()
    ev.stopPropagation()

    window.location = route('translation.export')
  })

  $('.js-translation-import').click((ev) => {
    ev.preventDefault()
    ev.stopPropagation()

    $('.js-translation-upload-file').click()
  })

  $(document).on('change', '.js-translation-upload-file', function () {

    let uploadFile = $('input.js-translation-upload-file')

    Swal.fire({
      title: 'Quiere importar este archivo?',
      text: 'Una vez importado, se modificarán todas las traducciones',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Importado!',
          'A continuación se modificaran las traducciones',
          'success',
        )

        let formData = new FormData()

        formData.append('file', uploadFile[0].files[0])

        axios.post(route('translation.import'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        uploadFile.val('')

        location.reload()
      }
    })
  })

  $('body').append(`<input type="file" class="js-translation-upload-file" hidden />`)

  //DRAG AND DROP FILE
  /*$('body').append(`
    <div id="import">
        <!-- File Drop Zone -->
        <div style="visibility:hidden; opacity:0" id="dropzone">
            <div id="textnode">Drop files to add data.</div>
        </div>
        <div id="results"></div>

    </div>
    <style>

    ul {
        list-style-type: none;
    }

    div#dropzone {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999999999;
        width: 100%;
        height: 100%;
        transition: visibility 175ms, opacity 175ms;
        display: table;
        text-shadow: 1px 1px 2px #000;
        color: #fff;
        background: rgba(0, 0, 0, 0.45);
        font: bold 42px Oswald, DejaVu Sans, Tahoma, sans-serif;
    }

    div#textnode {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        transition: font-size 175ms;
    }
</style>`)


  new Vue({
    el: '#import',
    data: function () {
      return {
        uploadedFiles: [],
      };
    },
    components: {
      swal
    },
    methods: {
      uploadFiles: function (f) {
        for (let i = 0; i < f.length; i++) {
          if (f[i].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            Swal.fire({
              title: 'Lo siento',
              text: `Este archivo no es de un tipo valido`,
              type: 'error',
            })
            continue
          } else {
            this.messageConfirmation(f[i])
          }
        }
      },

      loadFiles(file) {
        let formData = new FormData();
        formData.append("file", file);
        axios.post(route('translation.import'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      },
      messageConfirmation(file) {
        Swal.fire({
          title: "Quiere importar este archivo?",
          text: "Una vez importado, se modificarán todas las traducciones",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirmar'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
                'Importado!',
                'A continuación se modificaran las traducciones',
                'success'
            )
            this.loadFiles(file);
          }
        })
      },
    },
    mounted() {
      let self = this
      window.addEventListener("dragenter", function (e) {
        document.querySelector("#dropzone").style.visibility = "";
        document.querySelector("#dropzone").style.opacity = 1;
        document.querySelector("#textnode").style.fontSize = "48px";
      });

      window.addEventListener("dragleave", function (e) {
        e.preventDefault();

        document.querySelector("#dropzone").style.visibility = "hidden";
        document.querySelector("#dropzone").style.opacity = 0;
        document.querySelector("#textnode").style.fontSize = "42px";

      });

      window.addEventListener("dragover", function (e) {
        e.preventDefault();
        document.querySelector("#dropzone").style.visibility = "";
        document.querySelector("#dropzone").style.opacity = 1;
        document.querySelector("#textnode").style.fontSize = "48px";
      });

      window.addEventListener("drop", function (e) {
        e.preventDefault();
        document.querySelector("#dropzone").style.visibility = "hidden";
        document.querySelector("#dropzone").style.opacity = 0;
        document.querySelector("#textnode").style.fontSize = "42px";

        let files = e.dataTransfer.files;

        self.uploadFiles(files);
      });
    }
  })

*/
})

