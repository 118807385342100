$(() => {

  // TODO: metronic pone la cookie data-kt-aside-minimize, pero solo si es 'on', con lo que fallaría
  // TODO: el caso en que la configuración por defecto sea minimizado, y el usuario los expanda
  // TODO: Si aún así se prefiere la de metronic se puede cambiar

  const asideKey = 'aside-minimized'
  const asideButton = document.getElementById('m_aside_left_close_btn')
  const toggle =  KTToggle.getInstance(asideButton)

  toggle.on("kt.toggle.changed", function(e) {
    // Cookie de sesión, si se le quiere dar más duración cambiarlo
    document.cookie = `${asideKey}=${toggle.isEnabled()};path=/`
  });

})
